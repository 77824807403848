html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    background-color: #F5F5F5;
}

a {
    text-decoration: none;
    color: black;
}